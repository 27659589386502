:root {
    --color-primary: #3F3F3F;
    --color-primary-dark: #7380ec;
    --color-danger: #ff7782;
    --color-success: #41f1b6;
    --color-warning: #ffbb55;
    --color-white: #fff;
    --color-info-dark: #7d8da1;
    --color-primary-light: #F6F5FF;
    --color-toggle: #DDD;
    --color-text: #707070;
    --color-background: #f6f6f9;
    --color-light: rgba(132, 139, 200, 0.18);

    --card-border-radius: 2rem;
    --border-radius-1: 0.4rem;
    --border-radius-2: 0.8rem;
    --border-radius-3: 1.2rem;

    --card-padding: 1.8rem;
    --padding-1: 1.2rem;

    --box-shadow: 0 0.2rem 0.5rem var(--color-light);

    --tran-02: all 0.2s ease;
    --tran-03: all 0.3s ease;
    --tran-04: all 0.4s ease;
    --tran-05: all 0.5s ease;

}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
    margin-top: 4px;
    margin-bottom: 4px;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: lightgrey;
}

body {
    font-family: 'Inter', sans-serif;
    color: #3F3F3F;
}

.bg-orange {
    background-color: #FF6400;
}

.bg-cyan {
    background-color: #00D19F;
}

.bg-ungu {
    background-color: #7C00D1;
}

.bg-pink {
    background-color: #EC00C1;
}

.bg-indigo {
    background-color: #003160;
}

.bg-coklat {
    background-color: #AD5C00;
}

.bg-grey {
    background-color: #5B5B5B;
}

.bg-light {
    background-color: #EFF1FE !important;
}

.bg-warning-light {
    background-color: #FDFFAB !important;
}

.bg-orange-light {
    background-color: #FFE8C8 !important;
}

.bg-primary-light {
    background-color: #7BD3EA !important;
}

.bg-danger-light {
    background-color: #f77b7d !important;
}

.bg-success-light {
    background-color: #bfe3b5 !important;
}

@media (min-width: 1400px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1140px !important;
    }
}

.font-small {
    font-size: small;
}

.font-xsmall {
    font-size: x-small;
}

.spacer {
    height: 48px;
}

.spacer-64 {
    height: 64px;
}

.spacer-32 {
    height: 32px;
}

.spacer-24 {
    height: 24px;
}

/* SHIMER2 */

.is-loading {

    .image-lg,
    .image,
    .image-sm,
    .content1,
    .content2,
    .content3,
    .content4 {
        background: #eee;
        background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
        border-radius: 10px;
        background-size: 200% 100%;
        animation: 1.5s shine linear infinite;
    }

    .image-sm {
        height: 50px;
        /* border-bottom-left-radius: 0;
        border-bottom-right-radius: 0; */
    }

    .image {
        height: 140px;
        /* border-bottom-left-radius: 0;
        border-bottom-right-radius: 0; */
    }

    .image-lg {
        width: 100%;
        height: 200px;
        /* border-bottom-left-radius: 0;
        border-bottom-right-radius: 0; */
    }

    .content1 {
        height: 10px;
    }

    .content2 {
        height: 20px;
    }

    .content3 {
        height: 30px;
    }

    .content4 {
        height: 40px;
    }
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}

/* SHIMER2 */

.text-decoration-none {
    text-decoration: none;
    color: initial;
}

.cursor-pointer {
    cursor: pointer;
}

/* Sidebar */

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 250px;
    padding: 8px 8px;
    background: var(--color-white);
    transition: var(--tran-05);
    z-index: 4;
}

.sidebar.close {
    width: 80px;
}

.sidebar.d-none {
    width: 85px;
}

.sidebar header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 0;
    height: 42px;
}

.sidebar header .close-btn {
    display: none;
}

.sidebar .text {
    font-size: 14px;
    font-weight: 600;
    color: var(--color-text);
    transition: var(--tran-03);
    white-space: nowrap;
    opacity: 1;
}

.sidebar.close .text {
    opacity: 0;
}

.sidebar .image {
    min-width: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebar header {
    position: relative;
}

.sidebar header .image-text {
    display: flex;
    align-items: center;
}

.sidebar .image-text img {
    width: 32px;
    height: 32px;
}

.sidebar header .toggle {
    position: absolute;
    top: 50%;
    right: -24px;
    transform: translateY(-50%) rotate(180deg);
    width: 32px;
    height: 32px;
    background: var(--color-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: var(--color-white);
    transition: var(--tran-03);
    cursor: pointer;
    z-index: 999;
}


.sidebar header .toggle:hover {
    background: #707070;
    color: var(--color-white);
    transition: var(--tran-03);
}

.sidebar.close header .toggle {
    transform: translateY(-50%);
    z-index: 999;
}

.sidebar.d-none header .close-btn {
    transform: translateY(-50%);
    z-index: 999;
}



.sidebar .menu {
    /* margin-top: 24px; */
    direction: ltr;
    /* height: calc(100% - 64px);
    overflow-y: auto;
    overflow-x: hidden; */
}

.sidebar .bottom-content {
    /* margin-top: 24px; */
    direction: ltr;
    margin-bottom: 8px;
    /* height: calc(100% - 64px);
    overflow-y: auto;
    overflow-x: hidden; */
}

.sidebar li {
    height: 48px;
    margin-top: 8px;
    list-style: none;
    display: flex;
    align-items: center;
}

.sidebar li a {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    text-decoration: none;
    border-radius: 8px;
    transition: var(--tran-03);
}

.sidebar li a:hover {
    background: var(--color-primary);
}

.sidebar li .active {
    background: var(--color-primary);
}

.sidebar li a:hover .icon,
.sidebar li a:hover .text {
    color: var(--color-white) !important;
}

.sidebar li .active .icon,
.sidebar li .active .text {
    color: var(--color-white);
}

.sidebar li .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 56px;
    font-size: 24px;
}

.sidebar li .icon,
.sidebar li .text {
    color: var(--color-text);
    transition: var(--tran-02);
}

.sidebar .menu-bar {
    height: calc(100% - 36px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    direction: rtl;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 6px;
}

/* .menu-bar .menu li span.toltip {
    display: none;
} */

/* .sidebar.close .menu-bar .menu li span.toltip {
    display: block;
    position: absolute;
    left: 80px;
    background: var(--color-white);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius-1);
    padding: 4px 8px;
    font-weight: 600;
    font-size: 14px;
    transition: 0;
    opacity: 0;
    cursor: pointer;
    white-space: nowrap;
    color: var(--color-text);
} */

/* .sidebar.close .menu-bar .menu li:hover span.toltip {
    left: 90px;
    transition: var(--tran-03);
    opacity: 1;
} */

.menu-bar .mode {
    position: relative;
    background: var(--color-primary-light);
    border-radius: 8px;
}

.menu-bar .mode .moon-sun {
    height: 48px;
    width: 56px;
    display: flex;
    align-items: center;
}

.menu-bar .mode .moon-sun .icon {
    position: absolute;
    transition: var(--tran-03);
}

.menu-bar .mode .moon-sun .icon.sun {
    opacity: 1;
}

.menu-bar .mode .moon-sun .icon.moon {
    opacity: 0;
}

.home {
    position: relative;
    height: 100vh;
    left: 250px;
    width: calc(100% - 250px);
    transition: var(--tran-05);
}

.topbar-container {
    left: 250px;
    width: calc(100% - 250px);
    transition: var(--tran-05);
}

.sidebar.close~.home {
    left: 85px;
    width: calc(100% - 85px);
}

.sidebar.close~.topbar-container .top-bar {
    left: 85px;
    width: calc(100% - 85px);
}

.sidebar.close~.topbar-container .bottom-topbar {
    left: 85px;
    width: calc(100% - 85px);
}


.topbar-container .top-bar {
    position: fixed;
    left: 250px;
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 0 0.8rem;
    height: 4rem;
    background: var(--color-white);
    width: calc(100% - 250px);
    z-index: 3;
    transition: var(--tran-05);
}

.topbar-container .bottom-topbar {
    position: fixed;
    left: 250px;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: start;
    border-top: 1px solid #eeeeee;
    margin-top: 4rem;
    padding: 0.5rem 0.8rem;
    background: var(--color-white);
    width: calc(100% - 250px);
    z-index: 2;
    transition: var(--tran-05);
}

.topbar-container .top-bar button {
    display: none;
}

.topbar-container .top-bar .profile {
    display: flex;
    gap: 1rem;
    text-align: right;
    align-items: center;
    color: var(--color-text);
}

.profile-pic {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.profile-pic-lg {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    object-fit: cover;
}

.nav-pills {
    --bs-nav-pills-border-radius: var(--bs-border-radius);
    --bs-nav-pills-link-active-color: #fff;
    --bs-nav-pills-link-active-bg: var(--color-primary) !important;
}

.nav {
    --bs-nav-link-padding-x: 1rem;
    --bs-nav-link-padding-y: 0.5rem;
    --bs-nav-link-font-weight: ;
    --bs-nav-link-color: var(--color-primary-dark) !important;
    --bs-nav-link-hover-color: #5766f0;
    --bs-nav-link-disabled-color: var(--bs-secondary-color);
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.btn-round {
    width: 32px;
    height: 32px;
    background: var(--color-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: var(--color-white);
    transition: var(--tran-03);
    cursor: pointer;
    border: none;
}

.btn-round:hover {
    background: #707070;
    color: var(--color-white);
    transition: var(--tran-03);
}

.vline {
    width: 2px;
    min-height: 24px;
    border-radius: 2px;
    background-color: var(--color-text);
}

/* Mobile Version */

@media screen and (max-width:768px) {
    .sidebar.d-none header .close-btn {
        transform: translateY(-50%);
        z-index: 999;
    }
    .terapkan{
        width: 100%
    }
    .row > .filter{
        margin-top: .5em !important;
    }
    .table{
        width: 600px
    }
    .table-responsive{
        overflow-x: scroll
    }
    .sidebar header {
        height: 45px;
    }

    .sidebar header .toggle {
        display: none;
    }

    .sidebar header .close-btn {
        display: inline-block;
        cursor: pointer;
        color: var(--color-text);
    }

    .sidebar .menu-bar {
        height: calc(100% - 48px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .sidebar {
        position: fixed;
        left: -100%;
        background: var(--color-white);
        width: 250px;
        z-index: 4;
        padding: 10px 14px;
        transition: all .5s linear;
        display: none;
        animation: showMenu 400ms ease forwards;
    }
    /* .sidebar.d-none{
        left: 0;
        animation: showMenu 400ms ease forwards;

    } */
    @keyframes showMenu {
        to {
            left: 0;
        }
    }


    .home {
        position: relative;
        height: 100vh;
        left: 0;
        width: 100%;
        transition: var(--tran-05);
    }

    .topbar-container .top-bar {
        position: fixed;
        top: 0;
        left: 0;
        align-items: center;
        padding: 0 0.8rem;
        height: 4rem;
        background: var(--color-white);
        width: 100%;
        margin: 0;
        z-index: 3;
    }

    .topbar-container .bottom-topbar {
        position: fixed;
        top: 0;
        left: 0;
        align-items: center;
        padding: 0 0.8rem;
        height: 4rem;
        background: var(--color-white);
        width: 100%;
        margin: 0;
        z-index: 2;
    }

    .topbar-container .top-bar button {
        border: none;
        display: inline-block;
        background: transparent;
        cursor: pointer;
        color: var(--color-text);
        position: absolute;
        left: 1rem;
        line-height: 0;
    }

    .topbar-container .top-bar button span {
        font-size: 2rem;
        padding: 0;
        margin: 0;
    }
}

table.table > thead > tr > th {
    text-align: center;
    vertical-align: middle;
}
.header-text > .title{
    font-size: 16px
}

nav > .pagination > .page-item > a{
    cursor: pointer
}
/* <li className="page-item"><a className="page-link">Previous</a></li> */
.notAllow {
    position: absolute;
    text-align: center;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background: #e57b0acc;
    color: red;
    padding-top: 44%;
    backdrop-filter: blur(10px);
}